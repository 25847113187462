export enum UseCheckPermissionsForceType {
  BeesForceOperationsGlobalRead = 'BeesForceAdmin.OperationsGlobal.Read',
  BeesForceVOneCardRead = 'BeesForceAdmin.VOneCard.Read',
  BeesForceVTwoCardRead = 'BeesForceAdmin.VTwoCard.Read',
  BeesForceTaskGroupsRead = 'BeesForceAdmin.TaskGroups.Read',
  BeesForceTaskGroupsWrite = 'BeesForceAdmin.TaskGroups.Write',
  BeesForceAdminInviteUser = 'IdentityFE.AdminUser.Invite',
  BeesForceTaskManagementWrite = 'BeesForceAdmin.TaskManagement.Write',
  BeesForceVisitsManagerWrite = 'BeesForceAdmin.VisitManager.Write'
}
