import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available'
  },
  sidebar: {
    campaigns: 'Campaigns',
    audiences: 'Audiences'
  },
  audience: {
    listPage: {
      searchPlaceholder: 'Search by name',
      audience: 'Audiences',
      loading: 'Loading items...',
      estimatedTarget: 'Current estimated target',
      createdOn: 'Created on',
      lastUpdate: 'Updated on',
      campaigns: 'Campaigns',
      actions: 'Actions',
      estimatedTargetInfo: 'This conveys the estimated number of POCs in this audience.',
      lastUpdateInfo: 'This number is updated daily to reflect the estimated target audience.',
      addNew: 'Create new audience',
      openExportedFiles: 'Open Exported files',
      update: 'Update',
      itemsPerPage: 'Items per page: {value}',
      status: 'Status',
      statusProcessing: 'Processing',
      statusNotProcessed: 'Error',
      statusProcessedWithErrors: 'Partially processed',
      statusProcessedSuccess: 'Successfully processed',
      name: 'Name',
      created: {
        audienceCreated: 'Audience created!'
      },
      saved: {
        audienceSaved: 'Audience saved!'
      },
      noAudiences: {
        description: 'Your audiences will be shown here.',
        btnLabel: 'Create new audience'
      },
      errorGetAudiences: {
        description: "We couldn't load the list. Try reloading.",
        btnLabel: 'Reload list'
      },
      errorSearchingAudiences: {
        description: 'No results found. Try changing your search.',
        btnLabel: 'Clear search'
      }
    },
    audiencePage: {
      newAudience: 'New audience',
      manageAudience: 'Manage audience',
      leaveModal: {
        title: 'Leave audience creation?',
        body: 'All unsaved changes will be lost.',
        leaveButton: 'Leave',
        cancelButton: 'Cancel'
      },
      vendorModal: {
        title: 'Change vendor?',
        body: 'If you change vendor, any unsaved changes will be lost.'
      },
      countryModal: {
        title: 'Change country?',
        body: 'If you change country, any unsaved changes will be lost.'
      },
      sellerModal: {
        title: 'Change seller?',
        body: 'If you change seller, any unsaved changes will be lost.'
      },
      leaveButton: 'Yes, change',
      cancelButton: 'No, go back',
      exportingFileModal: {
        titleExportingFileModal: 'Exporting file',
        bodyExportingFileModal:
          'This may take some minutes. Go to Exported files for status updates and download the files when they are ready.',
        leaveButtonExportingFileModal: 'Go to Exported files',
        cancelButtonExportingFileModal: 'Close'
      },
      goBackHeaderModal: {
        title: 'Leave this page?',
        body: 'All unsaved changes, including uploaded files, will be lost.',
        leaveButton: 'Leave',
        cancelButton: 'Go back'
      }
    },
    deleteAudienceModal: {
      title: 'Delete audience?',
      buttonDelete: 'Yes, delete audience',
      buttonCancel: 'No, go back',
      default: 'This action cannot be undone.',
      linked:
        'Unreleased campaigns linked to this audience will appear in an Error state, and you will need to select new audiences for them.'
    },
    exportedFilesPage: {
      title: 'Exported files',
      table: {
        toolbar: {
          search: 'Search by name',
          refresh: 'Refresh'
        },
        header: {
          audienceName: 'Audience name',
          type: 'Type',
          fileName: 'File name',
          status: 'Status',
          actions: 'Actions'
        },
        columns: {
          types: {
            invalidPocs: 'Invalid POCs',
            csvAudience: 'CSV file'
          },
          status: {
            processing: 'Processing',
            done: 'Processed',
            failed: 'Failed'
          }
        }
      }
    }
  },
  actions: {
    edit: 'Edit',
    delete: 'Delete',
    download: 'Download'
  },
  sidebarCMS: {
    Home: 'Home',
    BeesCustomer: 'BEES Customer',
    BeesEngage: 'BEES Engage',
    MiNegocio: 'Mi Negocio',
    AudienceEditor: 'Audience Editor'
  },
  sidebarOffer: {
    OfferList: 'Offer list',
    AudienceBuilder: 'Audience builder'
  },
  useSidebarExperimentationPlatform: {
    Home: 'Experimentation Platform',
    AudienceEditor: 'Audience Editor'
  },
  useSidebarForce: {
    pageTasks: 'Tasks management',
    pageOperations: 'Operation panel',
    pageTaskGroups: 'Task groups',
    pageAudienceBuilder: 'Audience builder',
    pageVisitsManager: 'Visits'
  },
  sidebarMembership: {
    EarningRules: 'Earning Rules',
    AudienceBuilder: 'Audience builder'
  },
  sidebarDeliveryWindow: {
    DdcsList: 'DDCs Management',
    ClientsList: 'Delivery Windows',
    ImportsExports: 'Imports and Exports',
    Autoblock: 'Delivery Capacity Availability Parameters',
    Audiences: 'Audiences',
    Seller: 'Seller settings'
  }
};

export default enUS;
